import ArrowTopRightOnSquareIcon from "@heroicons/react/20/solid/ArrowTopRightOnSquareIcon";
import BookmarkFilledIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { styled as styling } from "@mui/material/styles";
import Link from "next/link";
import Color from "../../../assets/colors";

//Container
export const LinkStyled = styling(Link)({
  width: "100%",
  textDecoration: "none",
  color: Color.black,
});

export const HighlightContainer = styling(Box)((props) => ({
  width: "100%",
  borderRadius: "1.4rem",

  ...(props.highlight && {
    border: `0.15rem solid ${Color.chipGreen}`,
    animation: "gradient-border 2s infinite",
  }),

  "@keyframes gradient-border": {
    "0%": {
      borderColor: `${Color.chipGreen}`,
    },
    "50%": {
      borderColor: `${Color.buttonGreen}`,
    },
    "100%": {
      borderColor: `${Color.chipGreen}`,
    },
  },
}));

export const OuterContainer = styling(Box)((props) => ({
  position: "relative",
  backgroundColor: Color.white,

  borderRadius: "1.25rem",
  boxShadow: `0.25rem 0.25rem 0.625rem rgba(0, 0, 0, 0.16)`,

  textTransform: "capitalize",
}));

export const JobBannerContainer = styling(Box)((props) => ({
  borderRadius: "1.25rem 1.25rem 0 0",
  backgroundImage: `url("${props.jobBanner}")`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  height: "10rem",
}));

export const BoosterContainer = styling(Box)({
  display: "flex",
  justifyContent: "space-between",
  gap: "0.5rem",
  padding: "1.25rem 1.125rem",

  textTransform: "uppercase",
});

export const SaveButtonContainer = styling(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  background: Color.white,
  borderRadius: "30px",
  opacity: "0.7",
  width: "36px",
  height: "36px",
});

export const MobileShareIconsContainer = styling(Box)({
  position: "absolute",
  top: "0",
  right: "0",
});

export const JobPerksContainer = styling(Box)((props) => ({
  background: props.$slotMax
    ? "linear-gradient(90deg, #B0CD72 -0.98%, #6EC197 77.2%)"
    : "transparent",
  padding: "0.5rem 1.25rem",
  margin: "1rem -1rem",
}));

export const ApplyButtonContainer = styling(Box)({
  padding: "1rem 0 0.25rem",
});

export const DetailContainer = styling(Box)({
  display: "flex",
  flexDirection: "column",

  padding: "1.25rem 1rem",
  borderRadius: "0 0 1.25rem 1.25rem",
});

export const RowContainer = styling(Box)({
  position: "relative",
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
});

export const ColContainer = styling(Box)({
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  gap: "0.5rem",

  width: "100%",
});

export const ItemContainer = styling(Box)({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "0.5rem",
});

//Button
export const ButtonStyled = styling(Button)((props) => ({
  display: "block",
  margin: "0 auto",
  width: "60%",
  borderRadius: "30px",
  fontSize: "0.875rem",
  fontWeight: "700",
  backgroundColor: Color.buttonGreen,
  color: Color.white,
  textTransform: "capitalize",
  padding: "7px 0",
  border: `1px solid ${Color.buttonGreen}`,
  transition: "all 0.35s ease-in-out",

  "& .MuiButton-label": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  "@media (min-width: 1280px)": {
    "&:hover": {
      backgroundColor: Color.buttonHoverGreen,
    },

    "&:hover div": {
      "&.MuiCircularProgress-root": {
        color: Color.white,
        border: `1px solid ${Color.buttonHoverGreen}`,
      },
    },
  },

  "&:disabled": {
    border: `1px solid ${Color.lightGrey}`,
    backgroundColor: Color.grey,
    color: Color.darkGrey,
  },
}));

//Typography
export const TextStyled = styling(Typography)((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.25rem",

  ...(props.type === "JobTitle" && {
    fontSize: "1.25em",
    fontWeight: "700",
    width: "90%",
  }),

  ...(props.type === "JobSubtitle" && {
    fontSize: "1rem",
    fontWeight: "500",
    width: "100%",
    color: props?.red ? Color.reject : Color.black,
  }),

  ...(props.type === "JobPosted" && {
    display: "block",
    marginLeft: "auto",
    textTransform: "lowercase",
    fontSize: "0.875rem",
    fontWeight: "500",
  }),

  ...(props.type === "Company" && {
    fontSize: "1rem",
    fontWeight: "400",
    marginBottom: "0.5rem",
    overflowWrap: "anywhere",
  }),

  ...(props.type === "LastActive" && {
    textTransform: "initial",
    color: Color.darkGrey,
    fontSize: "0.75rem",
    fontWeight: "400",
  }),
}));

//Image
export const CommpanyLogoStyled = styling("img")({
  borderRadius: "0.625rem",
  marginRight: "1.5rem",
  objectFit: "contain",
});

export const JobBannerStyled = styling("img")({
  width: "auto",
  objectFit: "contain",
});

//Chips
export const HighlightChip = styling(Box)((props) => ({
  color: Color.white,
  borderRadius: "0.35rem",
  padding: "0.25rem 0.5rem",

  ...(props.hot && {
    fontSize: "0.75rem",
    fontWeight: "700",
    background: Color.hiredlyPurple,
  }),

  ...(props.spotlight && {
    "@keyframes gradient": {
      "0%": {
        backgroundPosition: "0% 30%",
      },
      "50%": {
        backgroundPosition: "100% 30%",
      },
      "100%": {
        backgroundPosition: "0% 30%",
      },
    },
    animation: `gradient 5s ease infinite`,
    backgroundImage: `linear-gradient(-45deg, #b0cd72, #6ec7cd, #b0cd72)`,
    backgroundSize: `300% 300%`,

    fontSize: "0.7rem",
    fontWeight: "500",
    letterSpacing: "0.1rem",

    display: "flex",
    alignItems: "center",
    gap: "0.25rem",
  }),
}));

export const JobChip = styling(Chip)((props) => ({
  color: Color.blue,
  backgroundColor: "rgba(33, 61, 181, 0.14)",
  marginLeft: props?.hasMarginLeft && "8px",

  fontWeight: "700",
  fontSize: "0.625rem",

  padding: "3px 10px",
}));

export const JobPerksChip = styling(Box)((props) => ({
  color: "rgba(0, 0, 0, 0.87)",
  backgroundColor: props.$slotMax ? Color.white : Color.lightHiredlyPurple,

  fontSize: "0.75rem",
  fontWeight: "400",
  lineHeight: "1.25rem",

  border: "0.5px solid rgba(0, 0, 0, 0.87)",
  borderRadius: "0.25rem",
  padding: "0 6px",
}));

//Icons
export const BookmarkBorderOutlinedIconStyled = styling(
  BookmarkBorderOutlinedIcon
)({
  width: "20px",
  height: "20px",
  color: Color.hiredlyPurple,
});

export const BookMarkFilledIconStyled = styling(BookmarkFilledIcon)({
  width: "20px",
  height: "20px",
  color: Color.hiredlyPurple,
});

export const ArrowIconStyled = styling(ArrowTopRightOnSquareIcon)({
  height: "19px",
  width: "20px",
  margin: "0 -6px -3px 6px",
});

//Etc
export const CircularProgressStyled = styling(CircularProgress)({
  "&.MuiCircularProgress-root": {
    width: "24px !important",
    height: "24px !important",
  },
});
export const CircularProgressWhite = styling(CircularProgress)({
  "&.MuiCircularProgress-root": {
    width: "24px !important",
    height: "24px !important",
    color: "white !important",
  },
});

export const DividerStyled = styling(Divider)({});
