import Cookies from "js-cookie";
import moment from "moment";
import { Fragment, useState } from "react";
import { InView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import * as config from "../../../config/config";
import { formatSalary } from "../../../helpers/conditional_rendering";
import { getGaSessionID } from "../../../helpers/data_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import { postGaSessionId } from "../../../redux/actions/job_action";
import { SharedSGTooltip } from "../../shared/SharedSGJobTooltip/SharedSGJobTooltip";
import {
  LocationIconStyled,
  MoneyIconStyled,
  SparklesIconStyled,
} from "../AccordionJobCard/styles";
import MobileShareIcons from "../Mobile/MobileShareIcons/MobileShareIcons.jsx";
import {
  ApplyButtonContainer,
  ArrowIconStyled,
  BookMarkFilledIconStyled,
  BookmarkBorderOutlinedIconStyled,
  BoosterContainer,
  ButtonStyled,
  CircularProgressStyled,
  CircularProgressWhite,
  ColContainer,
  DetailContainer,
  DividerStyled,
  HighlightChip,
  HighlightContainer,
  ItemContainer,
  JobBannerContainer,
  JobChip,
  JobPerksChip,
  LinkStyled,
  MobileShareIconsContainer,
  OuterContainer,
  RowContainer,
  SaveButtonContainer,
  TextStyled,
} from "./styles";

let isSavingJob = false;
let isApplyingJob = false;
let isSharingJob = false;

function MobileJobCard(props) {
  const {
    job,
    onApplyJobClicked,
    onFollowJobClicked,
    unSaveJob,
    currentId,
    origin,
    cardRef,
    views,
  } = props;
  const dispatch = useDispatch();

  const [shareJob, setShareJob] = useState("");
  const [isShareIconsOpen, setIsShareIconsOpen] = useState(false);

  const bookmarkingJob = useSelector((state) => state?.jobs?.bookmarkingJob);
  const unbookmarkJob = useSelector((state) => state?.jobs?.unbookmarkJob);

  const reduxUser = useSelector((state) => state?.user?.user);
  const applyingJob = useSelector((state) => state?.jobs?.applyingJob);

  const jobImage = job?.imageMobile
    ? `https:${job?.imageMobile}`
    : config.assetDomain +
      "/images/backgrounds/hiredly-jobs-malaysia-wobb-background-v1.png";

  function getTime(job) {
    let activeAtDate = job?.activeAt || job?.active_at;
    let jobActiveAt = moment(activeAtDate, "YYYYMMDD");
    let timeNow = moment(moment(), "YYYYMMDD");

    const time =
      timeNow.diff(jobActiveAt, "months") > 3
        ? null
        : timeNow.diff(jobActiveAt, "months") > 1
        ? timeNow.diff(jobActiveAt, "months") + " months ago"
        : moment(activeAtDate).fromNow();

    return time;
  }

  function handleTriggerGaSessionId(job) {
    const gaSessionId = getGaSessionID();

    if (gaSessionId) {
      dispatch(
        postGaSessionId({
          jobId: job?.id,
          sessionId: gaSessionId,
        })
      );
    }
  }

  const onJobCardClick = (job) => (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (isSavingJob || isApplyingJob || isSharingJob) return;

    // Send tracking cookies for view job on mobile job list page, cookies expire in 1 day
    sendTrackingEvent({
      event: "CE_job-view-ijp",
      "job-title": job.title,
      "job-id": job?.id,
      "job-tracks": JSON.stringify(job?.tracks),
      "job-company": job.company?.name,
      "company-id": job.company.id,
      "job-tracks":
        Array.isArray(job?.tracks) && job?.tracks.length > 0
          ? job?.tracks.map((trackObj) => trackObj.title).join(", ")
          : null,
      industry: job?.company?.industry ?? null,
      skills:
        Array.isArray(job?.skills) && job?.skills.length > 0
          ? job?.skills.map((skillObj) => skillObj.name).join(", ")
          : null,
      "min-salary": job?.structuredJobData?.baseSalary?.value?.minValue,
      "max-salary": job?.structuredJobData?.baseSalary?.value?.maxValue,
      "min-experience": job?.minYearsExperience ?? null,
      "max-experience": job?.maxYearsExperience ?? null,
    });

    Cookies.set("click-source", null);
    Cookies.set("job-id", job.id, { expires: 1 });
    Cookies.set("job-tracks", job.tracks, { expires: 1 });
    Cookies.set("job-name", job.title, { expires: 1 });
    Cookies.set("job-company", job.company?.name, { expires: 1 });
    Cookies.set("company-id", job.company?.id, { expires: 1 });

    // Open each job view in new tabs

    const url = `/jobs/${job.slug}`;

    window.open(url, "_blank");
  };

  const onTriggerSaveJob = (job) => (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (!job?.bookmark) {
      isSavingJob = true;
      if (onFollowJobClicked) {
        onFollowJobClicked(job?.id)
          .then((response) => {
            isSavingJob = false;
          })
          .catch((error) => {
            isSavingJob = false;
          });
      } else {
        isSavingJob = false;
      }
    } else {
      isSavingJob = true;
      if (unSaveJob) {
        unSaveJob(job?.id)
          .then((response) => {
            isSavingJob = false;
          })
          .catch((error) => {
            isSavingJob = false;
          });
      } else {
        isSavingJob = false;
      }
    }
  };

  const onTriggerApplyJob = (job, reduxUser) => async (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (!job.haveApplied) {
      isApplyingJob = true;

      await onApplyJobClicked(job, reduxUser);

      isApplyingJob = false;
    }
  };

  const onTriggerSocialShare = (job) => (event) => {
    // event.stopPropagation();
    // event.preventDefault();

    isSharingJob = true;
    setIsShareIconsOpen(true);
    setShareJob(job);
  };

  async function onHandleHide() {
    setIsShareIconsOpen(false);
    setTimeout(() => {
      isSharingJob = false;
    }, 500);
  }

  function trackImpressionInView(job) {
    switch (views) {
      case "Profile Based":
        sendTrackingEvent({
          event: "CE_job-impression-profile-for-you-ashley-recommendations",
          "job-id": job?.id,
          origin: "for_you_page",
        });
        break;

      case "Activity Based":
        sendTrackingEvent({
          event: "CE_job-impression-profile-for-you-activity-based",
          "job-id": job?.id,
          origin: "for_you_page",
        });
        break;

      case "View History":
        sendTrackingEvent({
          event: "CE_job-impression-profile-for-you-recently-viewed",
          "job-id": job?.id,
          origin: "for_you_page",
        });
        break;

      default:
        sendTrackingEvent({
          event: "CE_job-impression-job-list",
          "job-id": job?.id,
        });
        break;
    }
  }

  return (
    <InView
      style={{ width: "100%" }}
      onChange={(inView) => inView === true && trackImpressionInView(job)}
    >
      <HighlightContainer
        highlight={
          job?.jobSlotType === "job_slot_max" ||
          job?.jobSlotType === "job_slot_plus" ||
          (job?.spotlight && !job?.boosted)
        }
      >
        <OuterContainer ref={cardRef}>
          <LinkStyled href={`/jobs/${job.slug}`} onClick={onJobCardClick(job)}>
            <JobBannerContainer jobBanner={jobImage}>
              <BoosterContainer>
                <ItemContainer>
                  {job?.boosted ||
                  job?.spotlight ||
                  job?.jobSlotType === "job_slot_max" ||
                  job?.jobSlotType === "job_slot_plus" ? (
                    <>
                      {job?.boosted ? (
                        <HighlightChip hot={job?.boosted}>Hot</HighlightChip>
                      ) : null}
                      {job?.spotlight ||
                      job?.jobSlotType === "job_slot_max" ||
                      job?.jobSlotType === "job_slot_plus" ? (
                        <HighlightChip spotlight={job?.spotlight}>
                          <SparklesIconStyled /> Spotlight
                        </HighlightChip>
                      ) : null}
                    </>
                  ) : null}
                </ItemContainer>
                {job?.scraped ? null : (
                  <SaveButtonContainer onClick={onTriggerSaveJob(job)}>
                    {job?.bookmark ? (
                      unbookmarkJob && currentId === job?.id ? (
                        <CircularProgressStyled />
                      ) : (
                        <BookMarkFilledIconStyled />
                      )
                    ) : bookmarkingJob && currentId === job?.id ? (
                      <CircularProgressStyled />
                    ) : (
                      <BookmarkBorderOutlinedIconStyled />
                    )}
                  </SaveButtonContainer>
                )}
              </BoosterContainer>
            </JobBannerContainer>
            <DetailContainer>
              <RowContainer marginBottom="0.5rem">
                <ColContainer>
                  <TextStyled type="JobTitle">{job?.title}</TextStyled>{" "}
                  <TextStyled type="Company">
                    {job?.scraped && job?.scraped.toString().length > 0
                      ? job?.scraped
                      : job?.company?.name}
                  </TextStyled>
                </ColContainer>
                {origin === "for-you-page" ? null : (
                  <MobileShareIconsContainer>
                    <MobileShareIcons
                      selectedJob={shareJob}
                      job={job}
                      onTriggerSocialShare={onTriggerSocialShare}
                      isShareIconsOpen={isShareIconsOpen}
                      onHandleHide={onHandleHide}
                    />
                  </MobileShareIconsContainer>
                )}
              </RowContainer>
              <ColContainer marginBottom="1.5rem">
                {job?.stateRegion?.length > 0 ? (
                  <TextStyled
                    type="JobSubtitle"
                    red={job?.stateRegion === "Singapore"}
                  >
                    <LocationIconStyled
                      red={job?.stateRegion === "Singapore"}
                    />
                    {job?.stateRegion}
                  </TextStyled>
                ) : null}
                <TextStyled type="JobSubtitle">
                  <MoneyIconStyled />
                  {job?.salary === "Undisclosed" || job?.salary === "Undefined"
                    ? "Undisclosed"
                    : formatSalary(job?.salary, job?.crossBorderData)}
                </TextStyled>
              </ColContainer>
              <RowContainer marginBottom="0.5rem">
                {job?.stateRegion === "Singapore" && (
                  <>
                    <SharedSGTooltip
                      showWhenTap={true}
                      placement={"bottom-end"}
                      PopperProps={{
                        sx: {
                          margin: "-14px 0 0 -6px !important",
                        },
                      }}
                    />
                    <DividerStyled
                      orientation="vertical"
                      sx={{
                        height: "20px",
                        width: "4px",
                      }}
                    />
                  </>
                )}
                <JobChip
                  type="job-type"
                  label={job?.jobType}
                  size={"small"}
                  xs={1}
                  hasMarginLeft={job?.stateRegion === "Singapore"}
                />
                <TextStyled type="JobPosted">
                  {getTime(job) !== null ? getTime(job) : null}
                </TextStyled>
              </RowContainer>
              {job?.keywordHighlight &&
              job?.keywordHighlightText?.length > 0 ? (
                <>
                  <DividerStyled
                    orientation="horizontal"
                    sx={{ width: "100%", height: "5px" }}
                  />
                  <RowContainer
                    margin={"0 -1rem"}
                    padding={"0.5rem 1rem"}
                    sx={
                      job?.jobSlotType === "job_slot_max" && {
                        background:
                          "linear-gradient(90deg, #B0CD72 -0.98%, #6EC197 77.2%);",
                      }
                    }
                  >
                    <ItemContainer>
                      {job?.keywordHighlightText?.map((jobPerks, index) => {
                        return (
                          <JobPerksChip
                            key={index}
                            $slotMax={job?.jobSlotType === "job_slot_max"}
                          >
                            {jobPerks}
                          </JobPerksChip>
                        );
                      })}
                    </ItemContainer>
                  </RowContainer>
                </>
              ) : null}
              {/* {getLastActiveAtTime(job?.company?.lastActiveAt) !== null ? (
              job?.scraped ? null : (
                <TextStyled type="LastActive">
                  <GroupIconStyled />
                  Last active -{" "}
                  {getLastActiveAtTime(job?.company?.lastActiveAt)}
                </TextStyled>
              )
            ) : null} */}
              {origin === "for-you-page" ? null : (
                <ApplyButtonContainer>
                  <ButtonStyled
                    disabled={job?.haveApplied || job.haveApplied === undefined}
                    onClick={onTriggerApplyJob(job, reduxUser)}
                  >
                    {job?.haveApplied || job.haveApplied === undefined ? (
                      "Applied"
                    ) : applyingJob && currentId == job?.id ? (
                      <CircularProgressWhite />
                    ) : job?.scraped ? (
                      <Fragment>
                        {"Apply"}
                        <ArrowIconStyled />
                      </Fragment>
                    ) : (
                      "Apply"
                    )}
                  </ButtonStyled>
                </ApplyButtonContainer>
              )}
            </DetailContainer>
          </LinkStyled>
        </OuterContainer>
      </HighlightContainer>
    </InView>
  );
}

export default MobileJobCard;
