import { useMediaQuery } from "@mui/material";
import Cookies from "js-cookie";
import moment from "moment";
import { usePostHog } from "posthog-js/react";
import { InView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import Color from "../../../assets/colors";
import { formatSalary } from "../../../helpers/conditional_rendering";
import { getGaSessionID } from "../../../helpers/data_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  postGaSessionId,
  updateSelectedJob,
} from "../../../redux/actions/job_action";
import { updateShowSignInModalStatus } from "../../../redux/actions/navbar_action";
import { checkAuthentication } from "../../../redux/actions/user_action";
import { SharedSGTooltip } from "../../shared/SharedSGJobTooltip/SharedSGJobTooltip";
import {
  AiSummaryButton,
  BookMarkFilledIconStyled,
  BookmarkOutlineIconStyled,
  BoosterContainer,
  ChipContainer,
  CircularProgressStyled,
  CommpanyLogoStyled,
  CompanyDetailContainer,
  CompanyDetailSubContainer,
  CompanyLogoPlaceholder,
  DetailContainer,
  DividerStyled,
  DocumentTextIconStyled,
  ExtraInfoContainer,
  HighlightChip,
  HighlightContainer,
  ItemContainer,
  JobChip,
  JobDetailContainer,
  JobDetailSubContainer,
  JobPerksChip,
  LinkStyled,
  LocationIconStyled,
  MoneyIconStyled,
  OuterContainer,
  SidebarColor,
  SparklesIconStyled,
  TextStyled,
} from "./styles";

let isSavingJob = false;

function AccordionJobCard(props) {
  const {
    job,
    index,
    onFollowJobClicked,
    unSaveJob,
    currentId,
    origin,
    forYouPageTab,
    setShowAiSummary,
    showAiSummary,
    jobPreferenceToggle,
    setJobIndex,
    cardRef,
  } = props;
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const dispatch = useDispatch();

  const selectedJob = useSelector((state) => state?.jobs?.job?.id);
  const bookmarkingJob = useSelector((state) => state?.jobs?.bookmarkingJob);
  const unbookmarkJob = useSelector((state) => state?.jobs?.unbookmarkJob);

  const companyLogo =
    job?.company?.logo?.length > 0 ? `https:${job?.company?.logo}` : "";

  const posthog = usePostHog();
  // const hoverShowSummary = useFeatureFlagEnabled("eson-test-feature-flag");

  const belowLg = useMediaQuery("(max-width: 1279px)");

  function handleAiSummaryClick(event, job, index) {
    event.stopPropagation();
    event.preventDefault();

    setJobIndex(index);

    if (job.id === selectedJob && showAiSummary) {
      // To prevent spamming clicks of button
      return;
    } else {
      setShowAiSummary(true);
      dispatch(updateSelectedJob(job));
      sendTrackingEvent({
        event: "CE_click-gpt-job-list",
        "job-id": job?.id,
      });
    }
  }

  // function handleHoverAiSummaryClick(event, job, index) {
  //   if (belowLg) return;

  //   event.stopPropagation();
  //   event.preventDefault();

  //   setJobIndex(index);

  //   setShowAiSummary(true);
  //   dispatch(updateSelectedJob(job));
  // }

  function getTime(job) {
    let activeAtDate = job?.activeAt || job?.active_at;
    let jobActiveAt = moment(activeAtDate, "YYYYMMDD");
    let timeNow = moment(moment(), "YYYYMMDD");

    const time =
      timeNow.diff(jobActiveAt, "months") > 3
        ? null
        : timeNow.diff(jobActiveAt, "months") > 1
        ? timeNow.diff(jobActiveAt, "months") + " months ago"
        : moment(activeAtDate).fromNow();

    return time;
  }

  function handleTriggerGaSessionId(job) {
    const gaSessionId = getGaSessionID();

    if (gaSessionId) {
      dispatch(
        postGaSessionId({
          jobId: job?.id,
          sessionId: gaSessionId,
        })
      );
    }
  }

  function clickHandler(event, jobData) {
    event.stopPropagation();
    event.preventDefault();

    handleTriggerGaSessionId(job);

    const cleanSalary = jobData?.salary
      ?.split("-")
      .map((item) => item.replaceAll("RM", ""));

    if (origin === "for-you-page") {
      const forYouTabRename =
        forYouPageTab?.toString().toLowerCase().replace(" ", "_") ?? null;

      posthog.capture("click_job", {
        origin: "for_you_page",
        index: index,
        job_id: jobData?.id,
        job_title: jobData?.title,
        job_type: jobData?.jobType,
        spotlight: jobData?.spotlight,
        featured: jobData?.boosted,
        keyword: jobData?.keywordHighlight,
        scraped: jobData?.scraped ? true : false,
        job_slot_type: jobData?.jobSlotType,
        track_titles: jobData?.tracks?.map((track) => track.title) ?? [],
        company_id: jobData?.company.id,
        company_name: jobData?.company.name,
        location: jobData?.location,
        state_region: jobData?.stateRegion,
        hide_salary:
          !jobData?.salary ||
          jobData?.salary.toString().toLowerCase().includes("undisclosed")
            ? true
            : false,
        career_level: jobData?.careerLevel,
        min_years_experience: jobData?.minYearsExperience,
        max_years_experience: jobData?.maxYearsExperience,
        min_salary_range: parseInt(cleanSalary[0]) ?? 0,
        max_salary_range: parseInt(cleanSalary[cleanSalary.length - 1]) ?? 0,
      });

      sendTrackingEvent({
        event: "CE_click-job-profile-for-you",
        "job-id": jobData.id,
        "job-title": jobData.title,
        tab: forYouTabRename,
      });
    } else {
      Cookies.set("click-source", "job_listing_card");

      posthog.capture("click_job", {
        origin: "job_list",
        index: index,
        job_id: jobData?.id,
        job_title: jobData?.title,
        job_type: jobData?.jobType,
        spotlight: jobData?.spotlight,
        featured: jobData?.boosted,
        keyword: jobData?.keywordHighlight,
        scraped: jobData?.scraped ? true : false,
        job_slot_type: jobData?.jobSlotType,
        track_titles: jobData?.tracks?.map((track) => track.title) ?? [],
        company_id: jobData?.company.id,
        company_name: jobData?.company.name,
        location: jobData?.location,
        state_region: jobData?.stateRegion,
        hide_salary:
          !jobData?.salary ||
          jobData?.salary.toString().toLowerCase().includes("undisclosed")
            ? true
            : false,
        career_level: jobData?.careerLevel,
        min_years_experience: jobData?.minYearsExperience,
        max_years_experience: jobData?.maxYearsExperience,
        min_salary_range: parseInt(cleanSalary[0]) ?? 0,
        max_salary_range: parseInt(cleanSalary[cleanSalary.length - 1]) ?? 0,
      });

      sendTrackingEvent({
        event: "CE_click-job-job-list-card",
        "job-id": jobData.id,
        "job-title": jobData.title,
        spotlight: jobData.spotlight ? true : false,
        featured: jobData.featured ? true : false,
        keyword: jobData.keywordHighlight ? true : false,
        job_preference_toggle: jobPreferenceToggle ? "on" : "off",
      });
    }

    const url = `/jobs/${jobData.slug}`;

    window.open(url);
  }

  function trackImpressionInView(job, index) {
    if (origin === "for-you-page") {
      return;
    }

    const cleanSalary = job?.salary
      ?.split("-")
      .map((item) => item.replaceAll("RM", ""));

    posthog.capture("job_impression", {
      origin: "job_list",
      job_id: job?.id,
      job_title: job?.title,
      index: index,
      job_type: job?.jobType,
      spotlight: job?.spotlight,
      featured: job?.boosted,
      keyword: job?.keywordHighlight,
      scraped: job?.scraped ? true : false,
      job_slot_type: job?.jobSlotType,
      track_titles: job?.tracks?.map((track) => track.title) ?? [],
      company_id: job?.company.id,
      company_name: job?.company.name,
      location: job?.location,
      state_region: job?.stateRegion,
      hide_salary:
        !job?.salary ||
        job?.salary.toString().toLowerCase().includes("undisclosed")
          ? true
          : false,
      career_level: job?.careerLevel,
      min_years_experience: job?.minYearsExperience,
      max_years_experience: job?.maxYearsExperience,
      min_salary_range: parseInt(cleanSalary[0]) ?? 0,
      max_salary_range: parseInt(cleanSalary[cleanSalary.length - 1]) ?? 0,
    });

    sendTrackingEvent({
      event: "CE_job-impression-job-list",
      "job-id": job?.id,
      origin: "job_list",
    });
  }

  const onTriggerSaveJob = (job) => (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (!checkAuthentication()) {
      return dispatch(updateShowSignInModalStatus(true));
    }

    if (!job?.bookmark) {
      isSavingJob = true;
      if (onFollowJobClicked) {
        onFollowJobClicked(job?.id)
          .then((response) => {
            isSavingJob = false;
          })
          .catch((error) => {
            isSavingJob = false;
          });
      } else {
        isSavingJob = false;
      }
    } else {
      isSavingJob = true;
      if (unSaveJob) {
        unSaveJob(job?.id)
          .then((response) => {
            isSavingJob = false;
          })
          .catch((error) => {
            isSavingJob = false;
          });
      } else {
        isSavingJob = false;
      }
    }
  };

  return (
    <HighlightContainer
      highlight={
        job?.jobSlotType === "job_slot_max" ||
        job?.jobSlotType === "job_slot_plus" ||
        (job?.spotlight && !job?.boosted)
      }
    >
      <OuterContainer
        $selected_job={selectedJob === job.id && showAiSummary}
        ref={cardRef}
        // onMouseEnter={
        //   hoverShowSummary
        //     ? (event) => handleHoverAiSummaryClick(event, job, index)
        //     : () => {}
        // }
      >
        <LinkStyled
          href={`/jobs/${job.slug}`}
          onClick={(event) => clickHandler(event, job)}
        >
          {job?.boosted ? <SidebarColor highlight={job?.spotlight} /> : null}

          <BoosterContainer
            highlight={
              job?.boosted ||
              job?.spotlight ||
              job?.jobSlotType === "job_slot_max" ||
              job?.jobSlotType === "job_slot_plus"
            }
          >
            <ItemContainer>
              {job?.boosted ||
              job?.spotlight ||
              job?.jobSlotType === "job_slot_max" ||
              job?.jobSlotType === "job_slot_plus" ? (
                <>
                  {job?.boosted ? (
                    <HighlightChip hot={job?.boosted}>Hot</HighlightChip>
                  ) : null}
                  {job?.spotlight ||
                  job?.jobSlotType === "job_slot_max" ||
                  job?.jobSlotType === "job_slot_plus" ? (
                    <HighlightChip
                      spotlight={
                        job?.spotlight ||
                        job?.jobSlotType === "job_slot_max" ||
                        job?.jobSlotType === "job_slot_plus"
                      }
                    >
                      <SparklesIconStyled /> Spotlight
                    </HighlightChip>
                  ) : null}
                </>
              ) : null}
            </ItemContainer>
            {job?.scraped ? (
              <ItemContainer padding={"0.875rem 0"}></ItemContainer>
            ) : (
              <ItemContainer>
                {origin === "for-you-page" ? null : (
                  <AiSummaryButton
                    variant="outlined"
                    endIcon={<DocumentTextIconStyled />}
                    onClick={(event) => handleAiSummaryClick(event, job, index)}
                    $selected_job={selectedJob === job.id && showAiSummary}
                  >
                    ai summary
                  </AiSummaryButton>
                )}
                {job?.bookmark ? (
                  unbookmarkJob && currentId === job?.id ? (
                    <CircularProgressStyled />
                  ) : (
                    <BookMarkFilledIconStyled onClick={onTriggerSaveJob(job)} />
                  )
                ) : bookmarkingJob && currentId === job?.id ? (
                  <CircularProgressStyled />
                ) : (
                  <BookmarkOutlineIconStyled onClick={onTriggerSaveJob(job)} />
                )}
              </ItemContainer>
            )}
          </BoosterContainer>
          <DetailContainer>
            <JobDetailContainer>
              <InView
                style={{ width: "100%" }}
                onChange={(inView) =>
                  inView === true && trackImpressionInView(job, index)
                }
              >
                <TextStyled type="JobTitle">{job?.title}</TextStyled>
              </InView>
              <JobDetailSubContainer>
                <TextStyled type="JobSubtitle">
                  <MoneyIconStyled />
                  {job?.salary === "Undisclosed" || job?.salary === "Undefined"
                    ? "Undisclosed"
                    : formatSalary(job?.salary, job?.crossBorderData)}
                </TextStyled>
                {job?.stateRegion !== "" && job.stateRegion ? (
                  <TextStyled
                    type="JobSubtitle"
                    red={job?.stateRegion === "Singapore"}
                  >
                    <LocationIconStyled
                      red={job?.stateRegion === "Singapore"}
                    />
                    {job?.stateRegion}
                  </TextStyled>
                ) : null}
              </JobDetailSubContainer>
            </JobDetailContainer>
            <CompanyDetailContainer>
              {companyLogo ? (
                <CommpanyLogoStyled
                  height={56}
                  width={56}
                  alt={job.company.name}
                  src={companyLogo}
                />
              ) : (
                <CompanyLogoPlaceholder />
              )}
              <CompanyDetailSubContainer
                highlight={job?.boosted || job?.spotlight}
              >
                <TextStyled type="Company">
                  {job?.scraped && job?.scraped.toString().length > 0
                    ? job?.scraped
                    : job?.company?.name}
                </TextStyled>
                {/* {getLastActiveAtTime(job?.company?.lastActiveAt) !== null ? (
                  job?.scraped ? null : (
                    <TextStyled type="LastActive">
                      <GroupIconStyled />
                      Last active -{" "}
                      {getLastActiveAtTime(job?.company?.lastActiveAt)}
                    </TextStyled>
                  )
                ) : null} */}
              </CompanyDetailSubContainer>
            </CompanyDetailContainer>
          </DetailContainer>
          <ExtraInfoContainer
            sx={
              job?.jobSlotType === "job_slot_max" && {
                background:
                  "linear-gradient(90deg, #B0CD72 -0.98%, #6EC197 77.2%);",
              }
            }
          >
            {job?.stateRegion === "Singapore" && (
              <>
                <SharedSGTooltip
                  showWhenTap={!isDesktop ? true : false}
                  placement={"bottom-start"}
                />
                <DividerStyled
                  orientation="vertical"
                  $slotMax={job?.jobSlotType === "job_slot_max"}
                />
              </>
            )}
            <ItemContainer NoGap={true}>
              <JobChip
                type="job-type"
                label={job?.jobType}
                size={"small"}
                $slotMax={job?.jobSlotType === "job_slot_max"}
                xs={1}
              />
              {job?.keywordHighlight &&
                job?.keywordHighlightText?.length > 0 && (
                  <DividerStyled
                    orientation="vertical"
                    $slotMax={job?.jobSlotType === "job_slot_max"}
                  />
                )}
              {job?.keywordHighlight &&
              job?.keywordHighlightText?.length > 0 ? (
                <ChipContainer>
                  {job?.keywordHighlightText?.map((jobPerks, index) => {
                    return (
                      <JobPerksChip
                        key={index}
                        $slotMax={job?.jobSlotType === "job_slot_max"}
                      >
                        {jobPerks}
                      </JobPerksChip>
                    );
                  })}
                </ChipContainer>
              ) : null}
            </ItemContainer>
            <TextStyled
              type="JobPosted"
              color={
                job?.jobSlotType === "job_slot_max"
                  ? Color.white
                  : Color.darkGrey
              }
            >
              {getTime(job) !== null ? getTime(job) : null}
            </TextStyled>
          </ExtraInfoContainer>
        </LinkStyled>
      </OuterContainer>
    </HighlightContainer>
  );
}

export default AccordionJobCard;
