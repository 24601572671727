import BookmarkIcon from "@heroicons/react/24/outline/BookmarkIcon";
import DocumentTextIcon from "@heroicons/react/24/outline/DocumentTextIcon";
import SparklesIcon from "@heroicons/react/24/outline/SparklesIcon";
import BookmarkIconSolid from "@heroicons/react/24/solid/BookmarkIcon";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import GroupIcon from "@mui/icons-material/Group";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { styled as styling } from "@mui/material/styles";
import Link from "next/link";
import Color from "../../../assets/colors";

export const CountryFlagImage = styling("img")({
  height: "20px",
});

// Link Component //
export const LinkStyled = styling(Link)({
  width: "100%",
  textDecoration: "none",
  color: Color.black,
});

//Container
export const OuterContainer = styling(Box)((props) => ({
  position: "relative",
  backgroundColor: Color.white,

  borderRadius: "0.625rem",
  boxShadow: `0.25rem 0.25rem 0.625rem rgba(0, 0, 0, 0.16)`,

  textTransform: "capitalize",

  ...(props.$selected_job && {
    transition: "all 0.35s ease-in-out",
    boxShadow: `0 0 0 0.15rem ${Color.hiredlyPurple}`,
  }),

  ":hover": {
    transition: "all 0.15s ease-in-out",
    boxShadow: `0 0 0 0.15rem ${Color.hiredlyPurple}`,
  },
}));

export const HighlightContainer = styling(Box)((props) => ({
  width: "100%",
  borderRadius: "0.6rem",

  ...(props.highlight && {
    "@keyframes gradient-border": {
      "0%": {
        // border: "2px solid #6EC197",
        boxShadow: `0 0 0 0.15rem ${Color.chipGreen}`,
      },
      "50%": {
        // border: "2px solid #B0CD72",
        boxShadow: `0 0 0 0.15rem ${Color.buttonGreen}`,
      },
      "100%": {
        // border: "2px solid #6EC197",
        boxShadow: `0 0 0 0.15rem ${Color.chipGreen}`,
      },
    },
    animation: "gradient-border 2s infinite",
  }),
}));

export const SidebarColor = styling(Box)((props) => ({
  position: "absolute",
  background: Color.hiredlyPurple,
  borderRadius: "0.475rem 0 0 0.475rem",
  height: "100%",
  width: "0.625rem",
  zIndex: "10",

  ...(props.highlight && {
    "@keyframes sidebarGradient": {
      "0%": { backgroundPosition: "0% 100%" },
      "100%": { backgroundPosition: "0% 0%" },
    },

    animation: "sidebarGradient 1.75s linear infinite",
    backgroundImage: `linear-gradient(0deg,${Color.hiredlyPurple},${Color.hiredlyPurple},${Color.chipGreen},${Color.hiredlyPurple},${Color.hiredlyPurple})`,
    backgroundSize: "100% 600%",
  }),
}));

export const BoosterContainer = styling(Box)((props) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "0.5rem",

  textTransform: "uppercase",

  padding: "0.625rem 1rem 0 1.25rem",
  marginBottom: "-0.25rem",

  ...(!props.highlight && {
    marginBottom: "-2.125rem",
  }),
}));

export const DetailContainer = styling(Box)({
  display: "flex",
  padding: "0.625rem 1.25rem",
});

export const JobDetailContainer = styling(Box)((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",

  width: "50%",

  [props.theme.breakpoints.down("desktop")]: {
    width: "45%",
  },
}));

export const JobDetailSubContainer = styling(Box)({
  display: "flex",
  marginLeft: "-0.25rem",
});

export const JobSalaryLocationContainer = styling(Box)({
  display: "flex",
  alignItems: "center",
  gap: "16px",
});

export const IconTitle = styling(Box)((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "6px",
  minWidth: props.hasMinWidth && "170px",
}));

export const CompanyDetailContainer = styling(Box)((props) => ({
  display: "flex",
  width: "50%",
}));

export const CompanyDetailSubContainer = styling(Box)({
  display: "flex",
  flexDirection: "column",
  width: "45%",
});

export const ExtraInfoContainer = styling(Box)((props) => ({
  display: "flex",
  alignItems: "center",
  // justifyContent: "space-between",

  backgroundColor: Color.lightGrey,

  borderRadius: "0 0 0.625rem 0.625rem",
  padding: "0.625rem 1.25rem",
}));

export const ItemContainer = styling(Box)((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",

  ...(props.NoGap && {
    gap: "0",
  }),
}));

//Button
export const AiSummaryButton = styling(Button)((props) => ({
  fontWeight: "400",
  letterSpacing: "1.5px",
  fontSize: "0.625rem",
  borderRadius: "0.25rem",
  padding: "0.125rem 0.5rem",
  ...(props.$selected_job && {
    transition: "all 0.35s ease-in-out",
    color: Color.white,
    backgroundColor: Color.hiredlyPurple,
  }),

  ":hover": {
    color: Color.white,
    backgroundColor: Color.hiredlyPurple,
  },

  [props.theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

//Typography
export const TextStyled = styling(Typography)((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.25rem",

  ...(props.type === "JobTitle" && {
    fontSize: "1rem",
    fontWeight: "700",
    width: "100%",
  }),

  ...(props.type === "JobSubtitle" && {
    fontSize: "0.825rem",
    fontWeight: "400",
    width: "50%",
    color: props?.red ? Color.reject : Color.black,

    [props.theme.breakpoints.down("lg")]: {
      width: "80%",
    },
  }),

  ...(props.type === "Company" && {
    fontSize: "1rem",
    fontWeight: "600",
    marginBottom: "0.5rem",
    width: "92%",
    overflowWrap: "anywhere",
  }),

  ...(props.type === "JobPosted" && {
    display: "block",
    fontSize: "0.75rem",
    fontWeight: "500",
    marginLeft: "auto",
    textWrap: "nowrap",
  }),

  ...(props.type === "LastActive" && {
    color: Color.darkGrey,
    fontSize: "0.7rem",
    whiteSpace: "nowrap",
  }),
}));

export const SubtitleStyled = styling(Grid)((props) => ({
  fontSize: "0.8rem",
  marginLeft: "0.15rem",
  color: props.red && Color.reject,

  ...(props.type === "last-active" && {
    fontSize: "0.7rem",
    color: "hsla(0, 0%, 0%, 0.38)",
  }),

  ...(props.type === "JobPosted" && {
    color: Color.darkGrey,
    fontSize: "0.75rem",
    fontWeight: "500",
    justifyContent: "flex-end",
    width: "15%",
  }),

  ...(props.type === "Company" && {
    fontSize: "1rem",
    fontWeight: "600",
    marginBottom: "0.5rem",
    width: "92%",
  }),

  ...(props.type === "LastActive" && {
    color: Color.darkGrey,
    fontSize: "0.7rem",
    fontWeight: "500",
    letterSpacing: "0.1rem",

    display: "flex",
    alignItems: "center",
    gap: "0.25rem",

    padding: "0.125rem 0.5rem",
  }),
}));

//Image
export const CommpanyLogoStyled = styling("img")({
  borderRadius: "0.625rem",
  marginRight: "1rem",
  objectFit: "contain",
});

export const CompanyLogoPlaceholder = styling(Box)({
  width: "3.5rem",
  height: "3.5rem",
  maxHeight: "3.5rem",
  objectFit: "contain",
  borderRadius: "0.625rem",
  marginRight: "1.5rem",
});

//Icons
export const SparklesIconStyled = styling(SparklesIcon)({
  width: "1rem",
  height: "1rem",
});

export const DocumentTextIconStyled = styling(DocumentTextIcon)({
  width: "1rem",
  height: "1rem",
});

export const BookMarkFilledIconStyled = styling(BookmarkIconSolid)({
  width: "1.5rem",
  height: "1.5rem",
  color: Color.hiredlyPurple,
  cursor: "pointer",
  zIndex: "10",
});

export const BookmarkOutlineIconStyled = styling(BookmarkIcon)({
  width: "1.5rem",
  height: "1.5rem",
  color: Color.hiredlyPurple,
  cursor: "pointer",

  ":hover": {
    transition: "all 0.15s ease-in-out",
    strokeWidth: 3,
  },
  zIndex: "10",
});

export const MoneyIconStyled = styling(AttachMoneyIcon)({
  width: "1.1rem",
  height: "1.25rem",
  color: `rgba(0, 0, 0, 0.6)`,
});

export const LocationIconStyled = styling(LocationOnIcon)((props) => ({
  width: "1.1rem",
  height: "1.25rem",
  color: props.red ? Color.reject : "rgba(0, 0, 0, 0.6)",
}));

export const GroupIconStyled = styling(GroupIcon)({
  width: "1rem",
  height: "1rem",
  marginRight: "0.5rem",
  color: "hsla(0, 0%, 0%, 0.38)",
});

//Chips
export const HighlightChip = styling(Box)((props) => ({
  color: Color.white,
  borderRadius: "0.35rem",
  padding: "0.2rem 0.6rem",

  ...(props.hot && {
    fontSize: "0.75rem",
    fontWeight: "700",
    background: Color.hiredlyPurple,
  }),

  ...(props.spotlight && {
    "@keyframes gradient": {
      "0%": {
        backgroundPosition: "0% 30%",
      },
      "50%": {
        backgroundPosition: "100% 30%",
      },
      "100%": {
        backgroundPosition: "0% 30%",
      },
    },
    animation: `gradient 5s ease infinite`,
    backgroundImage: `linear-gradient(-45deg, #b0cd72, #6ec7cd, #b0cd72)`,
    backgroundSize: `300% 300%`,

    fontSize: "0.7rem",
    fontWeight: "500",
    letterSpacing: "0.1rem",

    display: "flex",
    alignItems: "center",
    gap: "0.25rem",
  }),
}));

export const ChipContainer = styling(Grid)({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "0.25rem",
});

export const JobChip = styling(Chip)((props) => ({
  color: Color.blue,
  backgroundColor: props.$slotMax ? Color.white : "rgba(33, 61, 181, 0.14)",

  fontWeight: "700",
  fontSize: "0.625rem",

  padding: "3px 10px",
}));

export const JobPerksChip = styling(Box)((props) => ({
  color: "rgba(0, 0, 0, 0.60)",
  backgroundColor: props.$slotMax ? Color.white : Color.lightHiredlyPurple,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  fontWeight: props.$slotMax ? "500" : "300",
  fontSize: "0.75rem",

  lineHeight: "1.25rem",
  whiteSpace: "nowrap",
  textTransform: "none",

  height: "1.25rem",
  padding: "0 0.375rem",

  border: `1px solid rgba(0, 0, 0, 0.87)`,
  borderRadius: "0.25rem",
}));

//Etc
export const CircularProgressStyled = styling(CircularProgress)({
  "&.MuiCircularProgress-root": {
    width: "24px !important",
    height: "24px !important",
  },
});

export const DividerStyled = styling(Divider)((props) => ({
  height: "20px",
  width: "1px",
  borderColor: props.$slotMax ? Color.white : "#A8A8A8",
  marginInline: "8px",
}));
