import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";
import SGFlag from "../../../public/images/SG.svg";

export const IconContainer = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  ":hover": {
    cursor: "pointer",
  },
});

export const TextStyled = styling(Typography)((props) => ({
  color: Color.black,
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: "1.375rem",
  letterSpacing: "0.15px",

  [props.theme.breakpoints.down("sm")]: {
    fontSize: "0.625rem",
  },
}));

export const LinkStyled = styling("a")({
  color: Color.hiredlyPurple,
  fontWeight: 700,
  textDecorationLine: "underline",
});

export const TooltipStyled = styling(Tooltip)({});

export const SGCountryFlag = styling(SGFlag)({
  width: "24px",
  height: "auto",
  objectFit: "contain",
});
